import { DynamicDialogConfig } from 'primeng/dynamicdialog/dynamicdialog-config';

export enum LockType {
  TIMESHEET,
  REPORT,
  DIARY,
  OPEN_CLOSE_DAYS,
  CLOCK_EMPLOYEE,
  BREAK_RULES,

  ADD_REQUIRED_SHIFT,
  ADD_OPEN_SHIFT,
  SEND_SCHEDULE,
  PUBLISH_SCHEDULE,
  FORECAST,
  BUDGET,
  AVAILABILITY,
  CALENDAR_SYNC,
  PRINT_SCHEDULE,
  ADD_ABSENCE,

  MY_HOURS,
  MY_ABSENCE,
  MY_PLUS_MINUS,

  SEND_MESSAGE,
  CHANGE_SALARY,
  CORRECT_BALANCES,

  EMPLOYEES,

  EMPLOYEE_CUSTOM_FIELDS,
  EMPLOYEE_CONTRACT_TYPES,

  OVERTIME,

  SKILLS,
  ABSENCE,
  TIME_TRACKING,
  TIME_TRACKING_NOTIFICATION,
  TIME_TRACKING_CUSTOM_FIELDS,
  CLOCKING,
  CLOCK_BOUNDARIES,
  KIOSK,

  SCHEDULE_CUSTOM_FIELDS,
  SCHEDULE_AVAILABILITY,
  SCHEDULE_NOTIFICATION,

  //can be removed after tmp_starter_to_basic is removed
  OLD_TIME_TRACKING,
  OLD_SCHEDULE_NOTIFICATION,

  OLD_EMPLOYEES,
  OLD_TIMESHEET,
}

export interface PlanLockMessage {
  title: string;
  message: string;
  knowledgebaseUrl: string;
}

export const planLockDialogConfig: DynamicDialogConfig = {
  width: '50%',
  dismissableMask: true,
  closeOnEscape: true,
  closable: true,
  showHeader: false,
};
